.KCL_view-news-article {
    background: $c-grey-f8;
    padding-bottom: 60px;

    h1 {
        .KCL_avatar {
            object {
                max-width: 100% !important;
            }
        }
    }

    @media print {
        .KCL_news-org-info {
            display: none;
        }
    }

    .time-and-category {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $c-grey-65;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        gap: 15px;
        margin-bottom: 30px;
        
        > span {
            width: fit-content;

            &.source {
                border-left: 1px solid $c-grey-65;
                padding-left: 15px;
            }
        }
    }

    .print-button {
        appearance: none;
        border: none;
        background: transparent;

        &:hover {
            color: $c-blue-22;
        }

        @media print {
            display: none;
        }
    }

    .news-body {
        display: flex;
        flex-direction: column;

        .summary-paragraph {
            margin-bottom: 30px;
        }

        .news-entry {
            overflow-x: auto;
            
            h3 {
                color: $c-dark-39;
            }

            img {
                object-fit: cover;
                aspect-ratio: 16/9;
                margin-bottom: 15px;
            }

            .ql-size-small {
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;
                font-style: italic;
                color: $c-grey-65;
            }

            table {
                margin-bottom: 30px;
                
                th {
                    color: $c-grey-4f;
                    padding: 14px 5px 12px;
                  
                    /* Tables / Large / Heading */
                    font-family: $ff-sans-serif;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 15px;
                    text-transform: uppercase;
                  
                    border-bottom: 1px solid $c-grey-d3;
                    background: linear-gradient(180deg, #FFF 83.75%, #F7F7F9 96.25%);
                }
                
                td {
                    padding: 16px 5px;
                    border: 0px;
                    border-bottom: 1px solid $c-grey-e9;
                    background: $c-white; 
                    color: $c-dark-23;
                
                    /* Tables / Large / Body text */
                    font-family: $ff-sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;

                    @include breakpoint-down(mobile-medium) {
                        font-size: 13px;
                        line-height: 16px;
                        padding: 12px 5px;
                    }
                }
            }

            ul {
                margin-top: 10px;
                padding-left: 20px;

                li {
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    &::marker {
                        color: $c-blue-6c;
                        font-size: 20px;
                    }
                }
            }

            ol {
                padding-left: 20px;
            }

            a {
                // Always
                padding: 0;
                background: transparent;
                text-decoration: none;
                width: fit-content;
                font-weight: 500;
                transition: color .3s, box-shadow .3s;
                box-shadow: inset 0 -0.5em 0 $c-teal-f2;
                // Initial
                color: $c-blue-1d;

                // Hover
                &:hover {
                    color: $c-blue-22;
                    box-shadow: inset 0 calc(-1em - 8px) $c-teal-f2;
                }

                // Focus
                &:focus {
                    color: $c-blue-1d;
                    box-shadow: inset 0 -0.5em $c-blue-d4;

                    // Focus and hover
                    &:hover {
                        color: $c-blue-22;
                        box-shadow: inset 0 calc(-1em - 8px) $c-blue-d4;
                    }
                }
            }

            p:last-child {
                margin-bottom: 20px;
            }
        }
    }

    .origin-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        
        .origin-label {
            font-size: 13px;
            line-height: 15px;
            font-weight: 700;
            color: $c-grey-bd;
        }

        .keldan-logo {
            padding-bottom: 10px;
        }
        
        .extra-info {
            margin-bottom: 0;
        }
    }

    .grid-items {
        .grid-item {
            &.grid-item--3of4 {
                float: left;
            }

            &.grid-item--1of4 {
                float: right;
            }

            .display-box:not(:last-child) {
                margin-bottom: 26px;
            }
        }
    }

    .KCL_news-attachments {
        ul {
            list-style: none;

            li {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                .KCL_link {
                    display: inline-block;
                    overflow: hidden;
                    max-width: 100%;
                }
            }
        }
    }
}