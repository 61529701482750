.company-search-page {
    .KCL_small-search {
        max-width: none;
    }

    .content_wrapper {
        background-color: $c-grey-f8;
        margin-top: 30px;
        padding-top: 30px;
        padding-bottom: 100px;
        
        .shell {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            @include breakpoint-down(tablet) {
                display: block;
            }

            .result_wrapper {
                width: calc(100% - 310px);
                padding-right: 65px;

                @include breakpoint-down(tablet) {
                    width: 100%;
                    padding-right: 0;
                }
                
                .table-section {
                    padding-top: 40px;
            
                    .table__body {
            
                        td {
                            > a {
                                &.clickableRow {
                                    padding-top: 10px
                                }
                            }
                        }
            
                        td span {
                            display: inline-block;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
            
                        @include breakpoint-down(mobile-medium) {
                            td span {
                                font-size: 13px;
            
                                &.name {
                                    max-width: 270px;
                                }
                                
                                &.companyName {
                                    max-width: 190px;
                                }
                            }
                        }
                    }
                }
            }

            .KCL_ad-rotator {
                margin: 0;
                position: sticky;
                top: 100px;

                @include breakpoint-down(tablet) {
                    position: static;
                    margin: {
                        top: 30px;
                        right: auto;
                        left: auto;
                    }
                }
            }
        }
    }
}