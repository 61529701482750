.KCL_crypto-logo,
.KCL_metal-logo {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $c-grey-f4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}