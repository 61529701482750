.KCL_news-org-info {
    .logo-wrapper {
        text-align: center;
        margin-bottom: 15px;

        img {
            max-width: 100%;
            max-height: 100px;
        }
    }

    .org-info-table {
        .info-title {
            font-size: 15px;
            line-height: 18px;
            font-weight: 700;
            padding: 0 5px;
            width: 80px;
            height: 36px;
            border: none;
        }

        td {
            font-size: 15px;
            line-height: 18px;
            font-weight: 400;
            padding: 0 5px;
            height: 36px;
            border: none;
        }
    }
    
    .org-description {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: $c-dark-39;
        margin-top: 10px;
    }
}