.KCL_ad-rotator {
    margin: 12px auto 24px auto;
    overflow: hidden;

    @media print {
        display: none;
    }

    &.ads1018x360,
    .ad1018x360 {
        width: 1018px;
        max-width: 100%;
        aspect-ratio: 1018 / 360;

        > iframe {
            height: 100%;
        }
    }
    
    &.ads400x80 {
        width: 400px;
        height: 80px;

        @include breakpoint-down(mobile-medium) {
            width: 300px;
            height: 60px;

            .KCL_ad.iframe-ad {
                width: 400px;
                height: 80px;
                max-width: 400px;
                max-height: 80px;
                margin-left: -50px;
                margin-top: -10px;

                > iframe,
                > div > iframe {
                    -webkit-transform: scale(0.75);
                    -moz-transform: scale(0.75);
                }
            }
        }
    }

    &.ads300x250 {
        width: 300px;
        height: 250px;
    }

    &.ads310x400 {
        width: 310px;
        height: 400px;
    }

    &.ads1360x180 {
        width: 1360px;
        height: 180px;
        max-width: 100%;
    }

    &.ads400x250 {
        width: 400px;
        height: 250px;

        @include breakpoint-down(mobile-medium) {
            max-width: 100%;
        }

        .inner {
            display: flex;
            align-items: center;

            .KCL_ad {
                height: fit-content;
                
                > a {
                    width: fit-content;
                    height: fit-content;
                    margin-left: auto;
                    margin-right: auto;

                    > img {
                        width: auto;
                        height: auto;
                    }
                }

                &.ad300x250 {
                    width: 300px;
                    height: 250px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    .inner,
    .KCL_ad,
    a,
    img {
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}