.KCL_searchPage {
    background-color: $c-grey-f8;
    overflow: unset !important;

    .filter_search_wrapper {
        display: flex;
        justify-content: space-between;
        gap: 25px;
        flex-wrap: wrap;
        margin-top: 10px;

        @include breakpoint-down(tablet) {
            flex-direction: column;
            row-gap: 30px;
        }

        .KCL_filter {
            position: relative;
            flex: 1;

            ul.filter_container {
                list-style: none;
                gap: 10px;
                padding-left: auto;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0;
            }

            li {
                break-inside: avoid;

                .KCL_filter-item {
                    margin: 0px;
                }

            }
        }
        
        #Search_page {
            flex: 1;
        }
    }

    .content_wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include breakpoint-up(small-desktop) {
            margin-right: 40px;
        }

        @include breakpoint-down(tablet) {
            display: block;
        }

        .result_wrapper {
            width: calc(100% - 310px);
            padding-right: 65px;

            @include breakpoint-down(tablet) {
                width: 100%;
                padding-right: 0;
            }
            
            .table-section {
                padding-top: 40px;

                &.company-search,
                &.thjodskra-search {
                    .table__body {
                        td:not(:last-child) {
                            width: 50%;
                        }
                    }
                }
        
                .table__body {
                    tr {
                        &:hover {
                            td > a {
                                span {
                                    color: $c-blue-1d;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
        
                    td {
                        > a {
                            display: inline-flex;
                            padding-top: 0px;
                            width: 100%;
                            height: 100%;
                            text-decoration: none;
        
                            span {
                                color: $c-dark-23;
                                letter-spacing: 0em;
                            }
        
                            &.anchorWrapForButton {
                                padding-top: 3px;
                                display: inline-block;
                            }
        
                            &.clickableRow {
                                padding-top: 10px
                            }

                            .KCL_label {
                                margin-left: 5px;
                            }
                        }
                    }
        
                    td span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
        
                    @include breakpoint-down(mobile-medium) {
                        td span {
                            &.name {
                                max-width: 270px;
                            }
                            
                            &.companyName {
                                max-width: 190px;
                            }
                        }
                    }
                }
            }
        }

        .KCL_ad-rotator {
            margin: 0;
            position: sticky;
            top: 100px;

            @include breakpoint-down(tablet) {
                position: static;
                margin: {
                    top: 30px;
                    right: auto;
                    left: auto;
                }
            }
        }
    }
}