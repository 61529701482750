.KCL_social-media-sharing {
    display: flex;
    align-items: center;
    gap: 20px;

    @media print {
        display: none;
    }

    .share-label {
        font-size: 13px;
        line-height: 15px;
        font-weight: 700;
        color: $c-grey-bd;
    }

    .share-icons {
        color: $c-dark-23;
        font-size: 20px;
        display: flex;
        gap: 20px;
        justify-content: space-between;
    }
}